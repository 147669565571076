import router from './router'

router.afterEach((to, from, next) => {
    window.gtag('config', 'G-HQLEL7TTNZ')
    // window.gtag('config', 'G-HQLEL7TTNZ', {
    //     page_title: title,
    //     page_path: page,
    //     page_location: location
    // })
    // console.log('title:' + JSON.stringify(to.meta.title))
    // console.log('path:' + JSON.stringify(to.path))
})